import React, { useState, useEffect } from "react";
import "../ResellBuyTicket/ResellBuyTicket.css";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import { Link, useLocation, useNavigate } from "react-router-dom";
import img1 from "../../assets/img/event-imgs/big-1.jpg";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import moment from "moment";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import { API_DOMAIN, GlobalConstants } from "../../utils/GlobalConst";
import ResellCheckout from "./ResellCheckout";
const xpath = require("xpath");
const dom = require("xmldom").DOMParser;
const stripePromise = loadStripe(
  "pk_test_51P1NMVRt5JCS1mrQ0R3SFrT76QoIF2ijszPDICSIVbRe3e8no4MMslSTWnwQcgHNBNXydKPCPIsWTccst8e367VO00xxgTd9xC"
);
function ResellBuyTicket() {
  const [text_confirmandpay, setText_confirmandpay] = useState("Confirm & Pay");
  const [text_total, setText_total] = useState("Total");
  const [text_total_ticket, setText_total_ticket] = useState("Total Tickets");
  const [text_billing, setText_billing] = useState("Billing Information");
  const [text_onceagain, setText_onceagain] = useState(
    "Please Check Email Once Again!"
  );
  const [text_usernotfound, setText_usernotfound] = useState("User Not Found");
  const [text_ownerpnem, setText_ownerpnem] = useState("Owner Email");
  const [text_owner_fullName, setText_owner_fullName] =
    useState("Owner Full Name");
  const [text_seat, setText_seat] = useState("Seat");
  const [text_quantity, setText_quantity] = useState("Quantity");
  const [text_price, setText_price] = useState("Price");
  const [text_totalpayable, setText_totalpayable] = useState(
    "Total Payable Amount"
  );
  const [text_orderconfirmation, setText_orderconfirmation] =
    useState("Order Confirmation");
  const [text_checkout, setText_checkout] = useState("Check Out");
  const [text_evedetailview, setText_evedetailview] =
    useState("Event Detail View");
  const [text_exploreevent, setText_exploreevent] = useState("Explore Events");
  const [text_home, setText_home] = useState("Home");
  const [text_seatname, setText_seatname] = useState("Seat Name");
  const [text_taxes_info, setText_taxes_info] = useState(
    "Price is inclusive of all applicable Taxes"
  );

  const navigate = useNavigate();
  const data = useLocation();
  const eveDetails = data.state !== null ? data.state.event_details : 0;
  const ticketDetails = data.state !== null ? data.state.ticket_details : 0;
  const clientSecret = data.state !== null ? data.state.client_secret : 0;
  const expiryEvent = data.state !== null ? data.state.event_expiry : 0;
  const defaultEmail = sessionStorage.getItem("useremail");
  const defaultUserName = sessionStorage.getItem("userfirstname");
  const [showPayment, setShowPayment] = useState(false);
  const [showOwnerErr, setShowOwnerErr] = useState(false);
  const [notFoundList, setNotFoundList] = useState([]);
  const [ownerEmailPhone, setownerEmailPhone] = useState(
    sessionStorage.getItem("useremail")
  );
  const [ownerName, setownerName] = useState(
    sessionStorage.getItem("userfirstname")
  );

  const saveOwnerDetails = () => {
    const data = [
      {
        seat_unique_id: ticketDetails[0].seat_unique_id,
        ownername:
          ownerName === ""
            ? sessionStorage.getItem("userfirstname")
            : ownerName,
        owneremail:
          ownerEmailPhone === ""
            ? sessionStorage.getItem("useremail")
            : ownerEmailPhone,
      },
    ];
    sessionStorage.setItem("ownerDetails", JSON.stringify(data));
    checkValidOwnerEmail(data[0].owneremail);
  };
  const checkValidOwnerEmail = (email) => {
    var API_URL = API_DOMAIN + `user/isuserexist?email=${email}`;

    let headerConfig = {
      headers: {
        authorization: "bearer " + sessionStorage.getItem("token"),
      },
    };

    var data = {};
    axios
      .post(API_URL, data, headerConfig)
      .then(function (response) {
        console.log("verify", response);
        if (response.status === 200) {
          setShowPayment(true);
        }
      })
      .catch(function (error) {
        if (error.response.status === 427) {
          sessionStorage.clear();
          localStorage.clear();
          setTimeout(() => {
            navigate("/");
          }, 1000);
        } else if (error.response.data.message === "user not found") {
          setShowOwnerErr(true);
          setNotFoundList([...notFoundList, error.response.data.data.email]);
        } else {
          toast.error(error.response.data.message);
        }
      })
      .then(function () {});
  };
  const handleBlur = () => {
    if (ownerEmailPhone === "") {
      setownerEmailPhone(defaultEmail);
    }
  };
  const handleBlurUserName = () => {
    if (ownerName === "") {
      setownerName(defaultUserName);
    }
  };
  const appearance = {
    theme: "stripe",
  };
  const options = {
    clientSecret,
    appearance,
  };
  useEffect(() => {
    try {
      SetLanguageText();
    } catch (err) {
      console.log("" + err);
    }
  }, []);

  const SetLanguageText = () => {
    try {
      var xml = localStorage.getItem(GlobalConstants.session_lang_xml);
      var doc = new dom().parseFromString(xml);
      setText_home(
        xpath.select1("/resources/string[@name='text_home']", doc).firstChild
          .data
      );
      setText_confirmandpay(
        xpath.select1("/resources/string[@name='text_confirmandpay']", doc)
          .firstChild.data
      );
      setText_total(
        xpath.select1("/resources/string[@name='text_total']", doc).firstChild
          .data
      );
      setText_total_ticket(
        xpath.select1("/resources/string[@name='text_total_ticket']", doc)
          .firstChild.data
      );
      setText_billing(
        xpath.select1("/resources/string[@name='text_billing']", doc).firstChild
          .data
      );
      setText_onceagain(
        xpath.select1("/resources/string[@name='text_onceagain']", doc)
          .firstChild.data
      );
      setText_usernotfound(
        xpath.select1("/resources/string[@name='text_usernotfound']", doc)
          .firstChild.data
      );
      setText_ownerpnem(
        xpath.select1("/resources/string[@name='text_ownerpnem']", doc)
          .firstChild.data
      );
      setText_owner_fullName(
        xpath.select1("/resources/string[@name='text_owner_fullName']", doc)
          .firstChild.data
      );
      setText_seat(
        xpath.select1("/resources/string[@name='text_seat']", doc).firstChild
          .data
      );
      setText_quantity(
        xpath.select1("/resources/string[@name='text_quantity']", doc)
          .firstChild.data
      );
      setText_price(
        xpath.select1("/resources/string[@name='text_price']", doc).firstChild
          .data
      );
      setText_totalpayable(
        xpath.select1("/resources/string[@name='text_totalpayable']", doc)
          .firstChild.data
      );
      setText_orderconfirmation(
        xpath.select1("/resources/string[@name='text_orderconfirmation']", doc)
          .firstChild.data
      );
      setText_checkout(
        xpath.select1("/resources/string[@name='text_checkout']", doc)
          .firstChild.data
      );
      setText_evedetailview(
        xpath.select1("/resources/string[@name='text_evedetailview']", doc)
          .firstChild.data
      );
      setText_exploreevent(
        xpath.select1("/resources/string[@name='text_exploreevent']", doc)
          .firstChild.data
      );
      setText_seatname(
        xpath.select1("/resources/string[@name='text_seatname']", doc)
          .firstChild.data
      );
      setText_taxes_info(
        xpath.select1("/resources/string[@name='text_taxes_info']", doc)
          .firstChild.data
      );
    } catch (ert) {}
  };
  return (
    <>
      <Header />

      <div className="wrapper" id="otherPages">
        <div className="breadcrumb-block">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-md-10">
                <div className="barren-breadcrumb">
                  <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">
                        <Link to="/">{text_home}</Link>
                      </li>
                      <li className="breadcrumb-item">
                        <Link to="/all-event">{text_exploreevent}</Link>
                      </li>
                      <li className="breadcrumb-item">
                        <Link to="/event-details">{text_evedetailview}</Link>
                      </li>
                      <li
                        className="breadcrumb-item active"
                        aria-current="page"
                      >
                        {text_checkout}
                      </li>
                    </ol>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="event-dt-block p-80">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-md-12">
                <div className="main-title checkout-title">
                  <h3>{text_orderconfirmation}</h3>
                </div>
              </div>
              <div className="col-xl-8 col-lg-12 col-md-12">
                <div className="checkout-block">
                  {showPayment === false ? (
                    <>
                      {" "}
                      <div className="main-card mt-5">
                        <div className="bp-title">
                          <h4>
                            {text_totalpayable} : {eveDetails?.sell_currency}{" "}
                            {Number(ticketDetails[0].seat_resell_price)
                              .toFixed(2)
                              .replace(/\d(?=(\d{3})+\.)/g, "$&,")}
                          </h4>
                        </div>
                        <div className="bp-content bp-form">
                          <table className="OwnerTable w-100">
                            <thead>
                              <tr>
                                <th className="OwnerTableHead">{text_seat}</th>
                                <th className="OwnerTableHead">
                                  {text_quantity}
                                </th>
                                <th className="OwnerTableHead">{text_price}</th>
                                <th className="OwnerTableHead">
                                  {text_owner_fullName}
                                </th>
                                <th className="OwnerTableHead">
                                  {text_ownerpnem}
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td className="OwnerTableBody p-2">
                                  {" "}
                                  {ticketDetails[0].seat_name}
                                </td>
                                <td className="OwnerTableBody p-2">1</td>
                                <td className="OwnerTableBody p-2">
                                  {eveDetails?.sell_currency}{" "}
                                  {Number(ticketDetails[0].seat_resell_price)
                                    .toFixed(2)
                                    .replace(/\d(?=(\d{3})+\.)/g, "$&,")}
                                </td>
                                <td className="OwnerTableBody p-2">
                                  <input
                                    type="text"
                                    className="form-control w-75 "
                                    value={ownerName}
                                    onChange={(e) => [
                                      setownerName(e.target.value),
                                      setShowOwnerErr(false),
                                    ]}
                                    onBlur={handleBlurUserName}
                                  />
                                </td>
                                <td className=" OwnerTableBody p-2">
                                  <input
                                    type="text"
                                    className="form-control w-75"
                                    value={ownerEmailPhone}
                                    onBlur={handleBlur}
                                    onChange={(e) => [
                                      setownerEmailPhone(e.target.value),
                                      setShowOwnerErr(false),
                                    ]}
                                  />
                                </td>
                              </tr>
                            </tbody>
                          </table>
                          {showOwnerErr === true ? (
                            <>
                              <p className="text-center validation-err">
                                {text_usernotfound}{" "}
                                <b>
                                  {" "}
                                  (
                                  {notFoundList.map((list) => {
                                    return <> &nbsp;{list}&nbsp;</>;
                                  })}
                                  )
                                </b>{" "}
                                {text_onceagain}
                              </p>
                            </>
                          ) : (
                            <></>
                          )}
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="main-card mt-5 p-4">
                        {clientSecret && (
                          <Elements options={options} stripe={stripePromise}>
                            <ResellCheckout
                              eID={eveDetails?.event_unique_id}
                              clientSecret={clientSecret}
                            />
                          </Elements>
                        )}
                      </div>
                    </>
                  )}
                </div>
              </div>
              <div className="col-xl-4 col-lg-12 col-md-12">
                <div className="main-card order-summary">
                  <div className="bp-title">
                    <h4>{text_billing}</h4>
                  </div>
                  <div className="order-summary-content p_30">
                    <div className="event-order-dt">
                      <div className="event-thumbnail-img">
                        <img
                          src={
                            eveDetails.EventImagePath === "path"
                              ? img1
                              : eveDetails.EventImagePath
                          }
                          alt=""
                        />
                      </div>
                      <div className="event-order-dt-content">
                        <h5>{eveDetails.EventTitle}</h5>
                        <span>
                          {" "}
                          {moment
                            .utc(eveDetails.StartDate)
                            .format("MMMM Do yyyy (dddd)")}
                          &nbsp;&nbsp;|&nbsp;&nbsp;
                          {moment.utc(eveDetails.StartTime).format("h:mmA")}
                        </span>
                        <div className="category-type">
                          {" "}
                          {eveDetails.venuedetails.length === 0 ? (
                            ""
                          ) : (
                            <>
                              {eveDetails?.venuedetails[0].venuename} ({" "}
                              {eveDetails?.venuedetails[0].address},{" "}
                              {eveDetails?.venuedetails[0].city},{" "}
                              {eveDetails?.venuedetails[0].state},{" "}
                              {eveDetails?.venuedetails[0].country},{" "}
                              {eveDetails?.venuedetails[0].zipcode})
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="order-total-block">
                      <div className="order-total-dt">
                        <div className="order-text">{text_total_ticket}</div>
                        <div className="order-number">1</div>
                      </div>
                      <div className="order-total-dt">
                        <div className="order-text">{text_seatname}</div>
                        <div className="order-number">
                          {" "}
                          {ticketDetails[0].seat_name}
                        </div>
                      </div>
                      <div className="divider-line"></div>
                      <div className="order-total-dt">
                        <div className="order-text">{text_total}</div>
                        <div className="order-number ttl-clr">
                          {" "}
                          {eveDetails?.sell_currency}{" "}
                          {Number(ticketDetails[0].seat_resell_price)
                            .toFixed(2)
                            .replace(/\d(?=(\d{3})+\.)/g, "$&,")}
                        </div>
                      </div>
                    </div>
                    {showPayment === false ? (
                      <>
                        {" "}
                        <div className="confirmation-btn">
                          <button
                            className="main-btn btn-hover h_50 w-100 mt-5"
                            type="button"
                            onClick={saveOwnerDetails}
                          >
                            {text_confirmandpay}
                          </button>
                          <span>{text_taxes_info}</span>
                        </div>
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
      <Footer />
    </>
  );
}

export default ResellBuyTicket;
