import React, { useEffect, useState } from "react";
import Header from "../Header/Header";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { FaTicketAlt } from "react-icons/fa";
import Footer from "../Footer/Footer";
import axios from "axios";
import { API_DOMAIN, GlobalConstants } from "../../utils/GlobalConst";
import { ToastContainer, toast } from "react-toastify";
import { Form } from "react-bootstrap";
import { v4 as uuidv4 } from "uuid";
import { MdAdd } from "react-icons/md";
import { RxCross2 } from "react-icons/rx";
import Loader from "../../utils/Loader";
const xpath = require("xpath");
const dom = require("xmldom").DOMParser;
function SeatConfiguration() {
  const _ = require("lodash");

  const data = useLocation();
  const eveid = data.state !== null ? data.state.event_id : false;
  const secid = data.state !== null ? data.state.section_id : false;
  const secName = data.state !== null ? data.state.sectionName : false;
  const currency = data.state !== null ? data.state.currency : false;
  const sell_currency = data.state !== null ? data.state.sell_currency : false;

  const navigate = useNavigate();
  const [selectedSectorName, setselectedSectorName] = useState("NA");
  const [seatDetails, setseatDetails] = useState({});
  const [showNext, setShowNext] = useState(false);
  const [isNext, setIsNext] = useState(false);
  const [selectedSeatIsDisable, setselectedSeatIsDisable] = useState(false);
  const [selectedSeatIsVIP, setselectedSeatIsVIP] = useState(false);
  const [selectedSeatPrice, setselectedSeatPrice] = useState(0);
  const [asdigitalticket, setAsdigitalticket] = useState(true);
  const [asexclusive, setAsexclusive] = useState(false);
  const [selectedBlocking, setSelectedBlocking] = useState(false);
  const [showVerifiedDetails, setShowVerifiedDetails] = useState(false);
  const [selectedPartnerDetail, setSelectedPartnerDetail] = useState(false);
  const [usercount, setUsercount] = useState(null);
  const [Loading, setLoading] = useState(false);

  const [text_save, setText_save] = useState("Save");
  const [text_close, setText_close] = useState("Close");
  const [text_next, setText_next] = useState("Next");
  const [text_verify, setText_verify] = useState("Verify");
  const [text_entercontactname, setText_entercontactname] =
    useState("Enter Contact Name");
  const [text_contactname, setText_contactname] = useState("Contact Name");
  const [text_enterpartnername, setText_enterpartnername] =
    useState("Enter Partner Name");
  const [text_partnername, setText_partnername] = useState("Partner Name");
  const [text_ph_mobile, setText_ph_mobile] = useState("Enter Phone NUmber");
  const [text_phoneNumber, setText_phoneNumber] = useState("Phone Number");
  const [text_ph_enterEmailId, setText_ph_enterEmailId] = useState(
    "Enter Your Email ID"
  );
  const [text_emailId, setText_emailId] = useState("Email Id");
  const [text_add, setText_add] = useState("Add");
  const [text_allocate, setText_allocate] = useState(
    "Allocate to Resell Partner"
  );
  const [text_blocktheseat, setText_blocktheseat] = useState("Block the seat");
  const [text_paperticket, setText_paperticket] = useState("Paper Ticket");
  const [text_digitalticket, setText_digitalticket] =
    useState("Digital Ticket");
  const [text_ticketType, setText_tickettype] = useState("Ticket Type");
  const [text_ticketcurr, setText_ticketcurr] = useState(
    "Ticket Currency & Price"
  );
  const [text_markasvip, setText_markasvip] = useState("Mark as vip");
  const [text_disableseats, setText_disableseats] = useState("Disable Seat");
  const [text_seat2, setText_seat2] = useState(
    "Seat configuration for section"
  );
  const [text_all, setText_all] = useState("Select all");
  const [text_configure, setText_configure] = useState("Configure Price");
  const [text_pendingconf, setText_pendingconf] = useState(
    "Pending Configuration"
  );
  const [text_selectedseat, setText_selectedseat] = useState("Selected Seat");
  const [text_disable, setText_disable] = useState("Disable");
  const [text_configuredseat, setText_configuredseat] =
    useState("Configured Seat");
  const [text_configuredseatof, setText_configuredseatof] =
    useState("Configured Seat of");
  const [text_configuredselectedseat, setText_configuredselectedseat] =
    useState("Configure Selected Section Seats");
  const [text_seatconfg, setText_seatconfg] = useState("Seat Configuration");
  const [text_eveconfig, setText_eveconfig] = useState(" Event Configuration");
  const [text_eveType, setText_eveType] = useState("Event Type");
  const [text_home, setText_home] = useState("Home");
  const [text_exclusive, setText_exclusive] = useState("Exclusive");
  const [text_nonexclusive, setText_nonexclusive] = useState("Non-Exclusive");
  const [inputFields, setInputFields] = useState([
    {
      partner_id: uuidv4(),
      partnername: "",
      contactname: "",
      email: "",
      mobile: "",
      verify: false,
    },
  ]);
  const handleChangeInput = (partner_id, event) => {
    const newInputFields = inputFields.map((i) => {
      if (partner_id === i.partner_id) {
        i[event.target.name] = event.target.value;
      }
      return i;
    });

    setInputFields(newInputFields);
  };
  const handleAddFields = () => {
    setInputFields([
      ...inputFields,
      {
        partner_id: uuidv4(),
        partnername: "",
        contactname: "",
        email: "",
        mobile: "",
        verify: false,
      },
    ]);
  };
  const handleRemoveFields = (partner_id) => {
    if (inputFields.length === 1) {
      toast.info("Minimum one Allocation is required");
    } else {
      const values = [...inputFields];
      values.splice(
        values.findIndex((value) => value.partner_id === partner_id),
        1
      );
      setInputFields(values);
    }
  };
  useEffect(() => {
    setselectedSectorName(secName);
  }, []);
  const confirmOwnerHandle = () => {
    for (let i = 0; i < inputFields.length; i++) {
      CheckValidEmail(inputFields[i]?.email);
    }
    // setPaymetTrue(true);
  };
  const CheckValidEmail = (email) => {
    setUsercount(0);
    var API_URL = API_DOMAIN + `user/isuserexist?email=${email}`;

    let headerConfig = {
      headers: {
        authorization: "bearer " + sessionStorage.getItem("token"),
      },
    };

    var data = {};
    axios
      .post(API_URL, data, headerConfig)
      .then(function (response) {
        console.log("verify", response, response.data.data.firstname);
        if (response?.status === 200) {
          for (let i = 0; i < inputFields.length; i++) {
            if (inputFields[i].email === email) {
              inputFields[i].partnername =
                response.data.data.firstname +
                " " +
                response.data.data.lastname;
              inputFields[i].contactname = response.data.data.firstname;
              inputFields[i].mobile = response.data.data.mobile;
              inputFields[i].verify = true;
              inputFields[i].partner_id = response.data.data.partner_id;
              setUsercount((usercount) => usercount + 1);
            }
          }
          console.log(inputFields); // setUsercount((usercount) => usercount + 1);
          toast.success("User Verified Successfully");
        }

        // var ownerData = response.data.data;
        // console.log("seat", ownerData);
      })
      .catch(function (error) {
        // errorToast(err);
        if (error.response.status === 427) {
          sessionStorage.clear();
          localStorage.clear();
          setTimeout(() => {
            navigate("/");
          }, 1000);
        } else if (error.response.data.message === "user not found") {
        } else {
          toast.error(error.response.data.message);
        }
      })
      .then(function () {});
  };
  const getSeatDetails = () => {
    setLoading(true);

    var API_URL =
      API_DOMAIN +
      `seat/view/event/seat?event_unique_id=${eveid}&seat_category_id=${secid}`;

    let headerConfig = {
      headers: {
        authorization: "bearer " + sessionStorage.getItem("token"),
      },
    };

    axios
      .get(API_URL, headerConfig)
      .then(function (response) {
        console.log("seatdetails List", response);
        setLoading(false);
        let data = response.data.data;
        var dataMain = data;
        //   setseatList(dataMain);
        const newArr = dataMain.map((v) => ({ ...v, ischecked: false }));
        ArrayTOObject(newArr);
      })
      .catch(function (error) {
        console.log(error);
        if (error.response.status === 427) {
          sessionStorage.clear();
          localStorage.clear();
          setTimeout(() => {
            navigate("/");
          }, 1000);
        } else {
          toast.error(error.response.data.message);
          setLoading(false);
        }
      })
      .then(function () {});
  };
  useEffect(() => {
    getSeatDetails();
  }, []);
  const ArrayTOObject = (arr) => {
    var seatss = arr,
      result = seatss.reduce(function (r, a) {
        r[a.rowstart] = r[a.rowstart] || [];
        r[a.rowstart].push(a);
        return r;
      }, Object.create(null));
    //sorting below
    var orderedData = Object.keys(result)
      .sort()
      .reduce((obj, key) => {
        obj[key] = result[key];
        return obj;
      }, {});
    //objs.sort((a,b) => a.column - b.column);
    for (const key in orderedData) {
      orderedData[key].sort((a, b) => a.column - b.column);
      //console.log(`${key}: ${orderedData[key]}`);
    }

    setseatDetails(orderedData);
    console.log(orderedData);
  };
  const selectAllSeat = (e) => {
    var temp = _.clone(seatDetails);

    Object.keys(seatDetails)?.map(function (rowstart) {
      var tempp = temp[rowstart];
      for (var i in tempp) {
        tempp[i].ischecked = e.target.checked;
      }
      temp[rowstart] = tempp;
    });
    setseatDetails(temp);
    if (showNext === false) {
      setShowNext(true);
    } else {
      // setShowNext(!showNext);
    }
    console.log("showNext", showNext);
    // setShowNext(true)
  };
  let checkAll = (obj) => {
    let ff = true;
    Object.keys(obj)?.map(function (rowstart) {
      let f = obj[rowstart].every((v) => v.ischecked === true);
      if (f) {
        //return false;
      } else {
        ff = false;
      }
    });
    return ff;
  };
  let checkRowAll = (arr) => arr.every((v) => v.ischecked === true);
  const SeatConfigure = (e, seat) => {
    console.log(e, seat);
    var temp = _.clone(seatDetails);
    var tempp = temp[seat.rowstart];
    for (let i in tempp) {
      if (tempp[i].seat_unique_id == seat.seat_unique_id) {
        tempp[i].ischecked = e.target.checked;
        break; //Stop this loop, we found it!
      }
    }
    temp[seat.rowstart] = tempp;
    setseatDetails(temp);
    console.log(tempp);
    for (let i = 0; i < tempp.length; i++) {
      if (tempp[i].ischecked === true) {
        console.log("true");
        setShowNext(true);
        return;
      } else {
        console.log("else");
        setShowNext(false);
      }
    }
    // if (showNext === false) {
    //   setShowNext(!showNext);
    // } else {
    //   setShowNext(!showNext);
    // }
  };

  const handleSelectFullRow = (seat, e) => {
    var temp = _.clone(seatDetails);
    var tempp = temp[seat.rowstart];
    //var temp = seatDetails[seat.rowstart];
    for (var i in tempp) {
      tempp[i].ischecked = e.target.checked;
      // if (temp[i].value == value) {
      //   temp[i].desc = desc;
      //    break; //Stop this loop, we found it!
      // }
    }
    temp[seat.rowstart] = tempp;
    setseatDetails(temp);
    //updateSeatDetails(API_URL, b);
    for (let i = 0; i < tempp.length; i++) {
      if (tempp[i].ischecked === true) {
        console.log("true");
        setShowNext(true);
        return;
      } else {
        console.log("else");
        setShowNext(false);
      }
    }
    // if (showNext === false) {
    //   setShowNext(!showNext);
    // } else {
    //   setShowNext(!showNext);
    // }
  };

  const submitSeats = () => {
    var temp = _.clone(seatDetails);
    var seatList = [];
    Object.keys(seatDetails)?.map(function (rowstart) {
      var tempp = temp[rowstart];
      for (var i in tempp) {
        if (tempp[i].ischecked == true) {
          seatList.push(tempp[i].seat_unique_id);
        }
      }
    });
    console.log(seatList);
    let b = {
      seatList: seatList,
      seatDetails: {
        event_unique_id: eveid,
        seat_category_id: secid,
        currency: sell_currency,
        seat_price: selectedSeatPrice,
        isdisable: selectedSeatIsDisable,
        //   isvisible: selectedSeatIsVisible,
        isvip: selectedSeatIsVIP,
        //   seatno: selectedSeatNo,
        ticket_type: asdigitalticket === true ? "digital" : "paper",
        isBlockByOwner: selectedBlocking,
        isExclusive: asexclusive,
        partner_emails: inputFields,
      },
    };

    var API_URL = API_DOMAIN + `seat/configure`;

    let headerConfig = {
      headers: {
        authorization: "bearer " + sessionStorage.getItem("token"),
      },
    };

    axios
      .put(API_URL, b, headerConfig)
      .then(function (response) {
        console.log("create", response);
        let data = response.data.data;

        if (response.status === 200) {
          setIsNext(false);
          setShowNext(false);
          getSeatDetails();
          setselectedSeatPrice(0);
          setselectedSeatIsVIP(false);
          setselectedSeatIsDisable(false);
          setSelectedBlocking(false);
          setSelectedPartnerDetail(false);
          setInputFields([
            {
              partner_id: uuidv4(),
              partnername: "",
              contactname: "",
              email: "",
              mobile: "",
              verify: false,
            },
          ]);
        }
      })
      .catch(function (error) {
        console.log(error);
        if (error.response.status === 427) {
          sessionStorage.clear();
          localStorage.clear();
          setTimeout(() => {
            navigate("/");
          }, 1000);
        } else {
          toast.error(error.response.data.message);
        }
      })
      .then(function () {});
  };
  useEffect(() => {
    try {
      SetLanguageText();
    } catch (err) {
      console.log("" + err);
    }
  }, []);

  const SetLanguageText = () => {
    try {
      var xml = localStorage.getItem(GlobalConstants.session_lang_xml);
      var doc = new dom().parseFromString(xml);
      setText_seat2(
        xpath.select1("/resources/string[@name='text_seat2']", doc).firstChild
          .data
      );
      setText_all(
        xpath.select1("/resources/string[@name='text_all']", doc).firstChild
          .data
      );
      setText_configuredseat(
        xpath.select1("/resources/string[@name='text_configuredseat']", doc)
          .firstChild.data
      );
      setText_configuredseatof(
        xpath.select1("/resources/string[@name='text_configuredseatof']", doc)
          .firstChild.data
      );
      setText_configuredselectedseat(
        xpath.select1(
          "/resources/string[@name='text_configuredselectedseat']",
          doc
        ).firstChild.data
      );
      setText_home(
        xpath.select1("/resources/string[@name='text_home']", doc).firstChild
          .data
      );
      setText_next(
        xpath.select1("/resources/string[@name='text_next']", doc).firstChild
          .data
      );

      setText_close(
        xpath.select1("/resources/string[@name='text_close']", doc).firstChild
          .data
      );

      setText_configure(
        xpath.select1("/resources/string[@name='text_configure']", doc)
          .firstChild.data
      );

      setText_eveType(
        xpath.select1("/resources/string[@name='text_eveType']", doc).firstChild
          .data
      );
      setText_pendingconf(
        xpath.select1("/resources/string[@name='text_pendingconf']", doc)
          .firstChild.data
      );
      setText_selectedseat(
        xpath.select1("/resources/string[@name='text_selectedseat']", doc)
          .firstChild.data
      );
      setText_disable(
        xpath.select1("/resources/string[@name='text_disable']", doc).firstChild
          .data
      );
      setText_seatconfg(
        xpath.select1("/resources/string[@name='text_seatconfg']", doc)
          .firstChild.data
      );
      setText_eveconfig(
        xpath.select1("/resources/string[@name='text_eveconfig']", doc)
          .firstChild.data
      );
      setText_disableseats(
        xpath.select1("/resources/string[@name='text_disableseats']", doc)
          .firstChild.data
      );
      setText_blocktheseat(
        xpath.select1("/resources/string[@name='text_blocktheseat']", doc)
          .firstChild.data
      );
      setText_paperticket(
        xpath.select1("/resources/string[@name='text_paperticket']", doc)
          .firstChild.data
      );
      setText_digitalticket(
        xpath.select1("/resources/string[@name='text_digitalticket']", doc)
          .firstChild.data
      );
      setText_tickettype(
        xpath.select1("/resources/string[@name='text_ticketType']", doc)
          .firstChild.data
      );
      setText_ticketcurr(
        xpath.select1("/resources/string[@name='text_ticketcurr']", doc)
          .firstChild.data
      );
      setText_markasvip(
        xpath.select1("/resources/string[@name='text_markasvip']", doc)
          .firstChild.data
      );
      setText_ph_enterEmailId(
        xpath.select1("/resources/string[@name='text_ph_enterEmailId']", doc)
          .firstChild.data
      );

      setText_verify(
        xpath.select1("/resources/string[@name='text_verify']", doc).firstChild
          .data
      );
      setText_allocate(
        xpath.select1("/resources/string[@name='text_allocate']", doc)
          .firstChild.data
      );
      setText_add(
        xpath.select1("/resources/string[@name='text_add']", doc).firstChild
          .data
      );
      setText_contactname(
        xpath.select1("/resources/string[@name='text_contactname']", doc)
          .firstChild.data
      );
      setText_enterpartnername(
        xpath.select1("/resources/string[@name='text_enterpartnername']", doc)
          .firstChild.data
      );
      setText_partnername(
        xpath.select1("/resources/string[@name='text_partnername']", doc)
          .firstChild.data
      );

      setText_ph_mobile(
        xpath.select1("/resources/string[@name='text_ph_mobile']", doc)
          .firstChild.data
      );
      setText_phoneNumber(
        xpath.select1("/resources/string[@name='text_phoneNumber']", doc)
          .firstChild.data
      );

      setText_entercontactname(
        xpath.select1("/resources/string[@name='text_entercontactname']", doc)
          .firstChild.data
      );
      setText_save(
        xpath.select1("/resources/string[@name='text_save']", doc).firstChild
          .data
      );
      setText_exclusive(
        xpath.select1("/resources/string[@name='text_exclusive']", doc)
          .firstChild.data
      );
      setText_nonexclusive(
        xpath.select1("/resources/string[@name='text_nonexclusive']", doc)
          .firstChild.data
      );
      setText_emailId(
        xpath.select1("/resources/string[@name='text_emailId']", doc).firstChild
          .data
      );
    } catch (ert) {}
  };
  return (
    <>
      <Header />
      <div
        className="Create-event-wrapper eventdetail-nightmod"
        id="otherPages"
      >
        <div className="breadcrumb-block">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-md-10">
                <div className="barren-breadcrumb">
                  <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">
                        <Link to="/">{text_home}</Link>
                      </li>
                      <li className="breadcrumb-item">
                        <Link to="/event-type">{text_eveType}</Link>
                      </li>
                      <li className="breadcrumb-item">
                        <Link to="/create-event/configuration">
                          {text_eveconfig}
                        </Link>
                      </li>
                      <li
                        className="breadcrumb-item active"
                        aria-current="page"
                      >
                        {text_seatconfg}
                      </li>
                    </ol>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="event-dt-block p-80">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-12 col-md-12">
                <div className="main-title text-center">
                  <h3>{text_configuredselectedseat}</h3>
                </div>
              </div>
              <div className="col-xl-12 col-lg-12 col-md-12">
                <div className="wizard-steps-block">
                  <div id="add-event-tab" className="step-app">
                    <div className="tab-from-content">
                      <div className="main-card">
                        <div className="bp-title">
                          <h4>
                            <FaTicketAlt className="step_icon me-3" />
                            {text_configuredseatof} {selectedSectorName}
                          </h4>
                        </div>

                        <div className="p-4 bp-form main-form">
                          <div className="form-group border_bottom-create profile-night-form pb_30">
                            {isNext === false ? (
                              <>
                                <div className="d-flex flex-wrap gap-4">
                                  <div className="d-flex gap-2">
                                    {" "}
                                    <div id="circle1"></div>
                                    {text_configuredseat}
                                  </div>
                                  <div className="d-flex gap-2">
                                    <div id="circle2"></div>
                                    {text_disable}
                                  </div>
                                  <div className="d-flex gap-2">
                                    <div id="circle3"></div>
                                    {text_selectedseat}
                                  </div>
                                  <div className="d-flex gap-2">
                                    <div id="circle4"></div>
                                    {text_pendingconf}
                                  </div>
                                </div>
                                <div className="ticket-section mt-3">
                                  <label className="form-label fs-16">
                                    {text_configure}
                                  </label>
                                  {Loading === true ? (
                                    <>
                                      <center className=" mt-3 mb-3">
                                        <Loader />
                                      </center>
                                    </>
                                  ) : (
                                    <>
                                      <div className="content-holder">
                                        <div className="">
                                          <input
                                            type="checkbox"
                                            className="selectcheckSTY"
                                            onChange={(e) => [
                                              selectAllSeat(e),
                                              // setShowNext(true),
                                            ]}
                                            checked={checkAll(seatDetails)}
                                          />
                                          <label htmlFor="">
                                            &nbsp; {text_all}
                                          </label>
                                        </div>
                                        <div className="row seatscroll ">
                                          {Object.keys(seatDetails)?.map(
                                            function (rowstart) {
                                              return (
                                                <>
                                                  <div className="p-3">
                                                    <div className=" d-flex gap-4 justify-content-between">
                                                      <div className="d-flex  gap-4 ">
                                                        <h5>
                                                          <input
                                                            type="checkbox"
                                                            className="mx-2 selectcheckSTY"
                                                            checked={checkRowAll(
                                                              seatDetails[
                                                                rowstart
                                                              ]
                                                            )}
                                                            onChange={(e) => [
                                                              handleSelectFullRow(
                                                                {
                                                                  rowstart:
                                                                    rowstart,
                                                                },
                                                                e
                                                              ),
                                                              // setShowNext(true),
                                                            ]}
                                                          />
                                                          {rowstart} .
                                                        </h5>
                                                        {seatDetails[
                                                          rowstart
                                                        ].map((seat, index) => {
                                                          return (
                                                            <>
                                                              {/* {console.log(seat)} */}
                                                              <div
                                                                className="seats gap-3"
                                                                type="A"
                                                              >
                                                                <div
                                                                  className={
                                                                    seat.isdisable ===
                                                                    true
                                                                      ? "seatDisable seat"
                                                                      : seat.isconfigure ===
                                                                        true
                                                                      ? "seatConfigured seat"
                                                                      : "seat"
                                                                  }
                                                                >
                                                                  <input
                                                                    type="checkbox"
                                                                    id={
                                                                      seat?._id
                                                                    }
                                                                    checked={
                                                                      seat.ischecked
                                                                    }
                                                                  />
                                                                  <label
                                                                    for={
                                                                      seat?._id
                                                                    }
                                                                  >
                                                                    {
                                                                      seat?.seat_name
                                                                    }{" "}
                                                                    <br />
                                                                    {
                                                                      seat.currency
                                                                    }{" "}
                                                                    {seat.seat_price ==
                                                                    null
                                                                      ? "null"
                                                                      : seat.seat_price}
                                                                  </label>
                                                                </div>
                                                                <input
                                                                  type="checkbox"
                                                                  className="selectcheckSTY mt-2"
                                                                  onChange={(
                                                                    e
                                                                  ) => [
                                                                    SeatConfigure(
                                                                      e,
                                                                      seat
                                                                    ),
                                                                    // setShowNext(
                                                                    //   true
                                                                    // ),
                                                                  ]}
                                                                  checked={
                                                                    seat.ischecked
                                                                  }
                                                                />
                                                              </div>
                                                            </>
                                                          );
                                                        })}
                                                      </div>
                                                      <div></div>
                                                    </div>
                                                  </div>
                                                </>
                                              );
                                            }
                                          )}
                                        </div>
                                      </div>
                                    </>
                                  )}
                                </div>
                              </>
                            ) : (
                              <></>
                            )}
                            {isNext === true ? (
                              <>
                                <div className="ticket-section">
                                  <h4 className="text-center text-decoration-underline">
                                    {text_seat2} : {selectedSectorName}
                                  </h4>
                                  {/* <h4 className="text-center">Selected Seat: Test_1</h4> */}
                                  {/* <hr /> */}
                                  <div className="p-5">
                                    <div className=" d-flex gap-5">
                                      <div>
                                        <input
                                          type="checkbox"
                                          className="checkboxconfigure"
                                          onChange={(e) => {
                                            setselectedSeatIsDisable(
                                              e.target.checked
                                            );
                                          }}
                                          checked={selectedSeatIsDisable}
                                        />
                                        <label
                                          htmlFor=""
                                          className="labelSTYPopup"
                                        >
                                          &nbsp; &nbsp;{text_disableseats}
                                        </label>
                                      </div>
                                      <div>
                                        <input
                                          type="checkbox"
                                          className="checkboxconfigure"
                                          onChange={(e) => {
                                            setselectedSeatIsVIP(
                                              e.target.checked
                                            );
                                          }}
                                          checked={selectedSeatIsVIP}
                                        />
                                        <label
                                          htmlFor=""
                                          className="labelSTYPopup"
                                        >
                                          &nbsp;&nbsp; {text_markasvip}
                                        </label>
                                      </div>
                                    </div>
                                    {selectedSeatIsDisable === false ? (
                                      <>
                                        <div className="row mt-4">
                                          <div className="col-md-12">
                                            <h5>
                                              <b>{text_ticketcurr}</b>
                                            </h5>
                                            <div className="d-flex gap-3">
                                              {/* <select
                                                className=" form-control w-25"
                                                value={currency}
                                                onChange={(e) =>
                                                  setCurrency(e.target.value)
                                                }
                                              >
                                                <option
                                                  value="usd"
                                                  selected
                                                  disabled
                                                >
                                                  usd
                                                </option>
                                              </select>{" "} */}
                                              <input
                                                className="seatcurrencyinput form-control"
                                                disabled
                                                value={sell_currency}
                                              />
                                              <input
                                                type="number"
                                                className=" form-control w-25"
                                                onChange={(e) => {
                                                  setselectedSeatPrice(
                                                    e.target.value
                                                  );
                                                }}
                                                value={selectedSeatPrice}
                                              />
                                            </div>
                                          </div>
                                        </div>
                                        <Form>
                                          <div className="row mt-4">
                                            <div className="col-md-12">
                                              <h5>
                                                <b>{text_ticketType}</b>
                                              </h5>
                                               
                                              <div className="d-flex gap-5">
                                                <Form.Check
                                                  // inline
                                                  label={text_digitalticket}
                                                  name="group1"
                                                  type="radio"
                                                  id="inline-radio-2"
                                                  style={{ fontSize: "large" }}
                                                  value="Digital Ticket"
                                                  checked={
                                                    asdigitalticket === true
                                                  }
                                                  onChange={() =>
                                                    setAsdigitalticket(true)
                                                  }
                                                />
                                                <Form.Check
                                                  // inline
                                                  label={text_paperticket}
                                                  name="group1"
                                                  type="radio"
                                                  id="inline-radio-1"
                                                  style={{ fontSize: "large" }}
                                                  checked={
                                                    asdigitalticket === false
                                                  }
                                                  onChange={() =>
                                                    setAsdigitalticket(false)
                                                  }
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        </Form>
                                        {asdigitalticket === true ? (
                                          <>
                                            <div className="row mt-4">
                                              <div>
                                                <input
                                                  type="checkbox"
                                                  className="checkboxconfigure"
                                                  onChange={(e) => [
                                                    setSelectedBlocking(
                                                      e.target.checked
                                                    ),
                                                    setInputFields([
                                                      {
                                                        partner_id: uuidv4(),
                                                        partnername: "",
                                                        contactname: "",
                                                        email: "",
                                                        mobile: "",
                                                      },
                                                    ]),
                                                    setShowVerifiedDetails(
                                                      false
                                                    ),
                                                  ]}
                                                  disabled={
                                                    selectedPartnerDetail ===
                                                    true
                                                      ? true
                                                      : false
                                                  }

                                                  // checked={selectedPartnerDetail}
                                                />
                                                <label
                                                  htmlFor=""
                                                  className="labelSTYPopup"
                                                >
                                                  &nbsp;&nbsp;{" "}
                                                  {text_blocktheseat}
                                                </label>
                                              </div>
                                              <div className="row mt-2">
                                                {selectedBlocking === true ? (
                                                  <>
                                                    {inputFields.map(
                                                      (inputField) => {
                                                        return (
                                                          <>
                                                            <div className="col-md-3">
                                                              <h5>
                                                                {text_emailId}
                                                              </h5>
                                                              <input
                                                                type="email"
                                                                className="form-control "
                                                                placeholder={
                                                                  text_ph_enterEmailId
                                                                }
                                                                name="email"
                                                                value={
                                                                  inputField.email
                                                                }
                                                                onChange={(
                                                                  event
                                                                ) =>
                                                                  handleChangeInput(
                                                                    inputField.partner_id,
                                                                    event
                                                                  )
                                                                }
                                                              />
                                                            </div>
                                                            {showVerifiedDetails ===
                                                            true ? (
                                                              <>
                                                                <div className="col-md-3">
                                                                  <h5>
                                                                    {
                                                                      text_phoneNumber
                                                                    }
                                                                  </h5>
                                                                  <input
                                                                    type="number"
                                                                    className="form-control "
                                                                    placeholder={
                                                                      text_ph_mobile
                                                                    }
                                                                    value={
                                                                      inputField.mobile
                                                                    }
                                                                    name="mobile"
                                                                    onChange={(
                                                                      event
                                                                    ) =>
                                                                      handleChangeInput(
                                                                        inputField.partner_id,
                                                                        event
                                                                      )
                                                                    }
                                                                  />
                                                                </div>
                                                                <div className="col-md-3">
                                                                  <h5>
                                                                    {
                                                                      text_partnername
                                                                    }
                                                                  </h5>
                                                                  <input
                                                                    type="text"
                                                                    className="form-control "
                                                                    placeholder={
                                                                      text_enterpartnername
                                                                    }
                                                                    value={
                                                                      inputField.partnername
                                                                    }
                                                                    name="partnername"
                                                                    onChange={(
                                                                      event
                                                                    ) =>
                                                                      handleChangeInput(
                                                                        inputField.partner_id,
                                                                        event
                                                                      )
                                                                    }
                                                                  />
                                                                </div>
                                                                <div className="col-md-3">
                                                                  <h5>
                                                                    {
                                                                      text_contactname
                                                                    }
                                                                  </h5>
                                                                  <input
                                                                    type="text"
                                                                    className="form-control "
                                                                    placeholder={
                                                                      text_entercontactname
                                                                    }
                                                                    value={
                                                                      inputField.contactname
                                                                    }
                                                                    name="contactname"
                                                                    onChange={(
                                                                      event
                                                                    ) =>
                                                                      handleChangeInput(
                                                                        inputField.partner_id,
                                                                        event
                                                                      )
                                                                    }
                                                                  />
                                                                </div>
                                                              </>
                                                            ) : (
                                                              <></>
                                                            )}
                                                          </>
                                                        );
                                                      }
                                                    )}
                                                    {showVerifiedDetails ===
                                                    false ? (
                                                      <>
                                                        <div className="my-2">
                                                          <button
                                                            className="main-btn"
                                                            onClick={
                                                              confirmOwnerHandle
                                                            }
                                                          >
                                                            <>{text_verify}</>
                                                          </button>
                                                        </div>
                                                      </>
                                                    ) : (
                                                      <></>
                                                    )}
                                                  </>
                                                ) : (
                                                  ""
                                                )}
                                              </div>
                                            </div>
                                            <div className="row mt-4">
                                              <div>
                                                <input
                                                  type="checkbox"
                                                  className="checkboxconfigure"
                                                  onChange={(e) => [
                                                    setSelectedPartnerDetail(
                                                      e.target.checked
                                                    ),
                                                    setInputFields([
                                                      {
                                                        partner_id: uuidv4(),
                                                        partnername: "",
                                                        contactname: "",
                                                        email: "",
                                                        mobile: "",
                                                      },
                                                    ]),
                                                    setShowVerifiedDetails(
                                                      false
                                                    ),
                                                    setAsexclusive(true),
                                                  ]}
                                                  disabled={
                                                    selectedBlocking === true
                                                      ? true
                                                      : false
                                                  }
                                                  // checked={selectedPartnerDetail}
                                                />
                                                <label
                                                  htmlFor=""
                                                  className="labelSTYPopup"
                                                >
                                                  &nbsp;&nbsp; {text_allocate}
                                                </label>
                                              </div>
                                              {selectedPartnerDetail ===
                                              true ? (
                                                <>
                                                  <Form>
                                                    <div className=" d-flex gap-5 mt-4">
                                                      <Form.Check
                                                        // inline
                                                        label={text_exclusive}
                                                        name="group1"
                                                        type="radio"
                                                        id="inline-radio-2"
                                                        style={{
                                                          fontSize: "large",
                                                        }}
                                                        value="exclusive"
                                                        checked={
                                                          asexclusive === true
                                                        }
                                                        onChange={() => [
                                                          setAsexclusive(
                                                            !asexclusive
                                                          ),
                                                          setInputFields([
                                                            {
                                                              partner_id:
                                                                uuidv4(),
                                                              partnername: "",
                                                              contactname: "",
                                                              email: "",
                                                              mobile: "",
                                                            },
                                                          ]),
                                                          setShowVerifiedDetails(
                                                            false
                                                          ),
                                                        ]}
                                                      />
                                                      <Form.Check
                                                        // inline
                                                        label={
                                                          text_nonexclusive
                                                        }
                                                        name="group1"
                                                        type="radio"
                                                        id="inline-radio-1"
                                                        style={{
                                                          fontSize: "large",
                                                        }}
                                                        checked={
                                                          asexclusive === false
                                                        }
                                                        onChange={() => [
                                                          setInputFields([
                                                            {
                                                              partner_id:
                                                                uuidv4(),
                                                              partnername: "",
                                                              contactname: "",
                                                              email: "",
                                                              mobile: "",
                                                            },
                                                          ]),
                                                          setShowVerifiedDetails(
                                                            false
                                                          ),
                                                          setAsexclusive(
                                                            !asexclusive
                                                          ),
                                                        ]}
                                                      />
                                                    </div>
                                                  </Form>
                                                  {asexclusive === false ? (
                                                    <>
                                                      <div className="text-start my-2">
                                                        <button
                                                          className="main-btn "
                                                          onClick={() => [
                                                            handleAddFields(),
                                                            setShowVerifiedDetails(
                                                              false
                                                            ),
                                                          ]}
                                                        >
                                                          <MdAdd className="me-1" />
                                                          {text_add}
                                                        </button>
                                                      </div>
                                                    </>
                                                  ) : (
                                                    <></>
                                                  )}{" "}
                                                  {inputFields.map(
                                                    (inputField, i) => {
                                                      return (
                                                        <>
                                                          <div className="row mt-2">
                                                            <div className="col-md-3">
                                                              <h5>
                                                                {text_emailId}
                                                              </h5>
                                                              <input
                                                                type="email"
                                                                className="form-control "
                                                                placeholder={
                                                                  text_ph_enterEmailId
                                                                }
                                                                value={
                                                                  inputField.email
                                                                }
                                                                name="email"
                                                                onChange={(
                                                                  event
                                                                ) =>
                                                                  handleChangeInput(
                                                                    inputField.partner_id,
                                                                    event
                                                                  )
                                                                }
                                                              />
                                                            </div>

                                                            {showVerifiedDetails ===
                                                            true ? (
                                                              <>
                                                                <div className="col-md-3">
                                                                  <h5>
                                                                    {
                                                                      text_phoneNumber
                                                                    }
                                                                  </h5>
                                                                  <input
                                                                    type="number"
                                                                    className="form-control "
                                                                    placeholder={
                                                                      text_ph_mobile
                                                                    }
                                                                    value={
                                                                      inputField.mobile
                                                                    }
                                                                    name="mobile"
                                                                    onChange={(
                                                                      event
                                                                    ) =>
                                                                      handleChangeInput(
                                                                        inputField.partner_id,
                                                                        event
                                                                      )
                                                                    }
                                                                  />
                                                                </div>
                                                                <div className="col-md-3">
                                                                  <h5>
                                                                    {
                                                                      text_partnername
                                                                    }
                                                                  </h5>
                                                                  <input
                                                                    type="text"
                                                                    className="form-control "
                                                                    placeholder={
                                                                      text_enterpartnername
                                                                    }
                                                                    value={
                                                                      inputField.partnername
                                                                    }
                                                                    name="partnername"
                                                                    onChange={(
                                                                      event
                                                                    ) =>
                                                                      handleChangeInput(
                                                                        inputField.partner_id,
                                                                        event
                                                                      )
                                                                    }
                                                                  />
                                                                </div>
                                                                <div className="col-md-3">
                                                                  <h5>
                                                                    {
                                                                      text_contactname
                                                                    }
                                                                  </h5>
                                                                  <input
                                                                    type="text"
                                                                    className="form-control "
                                                                    placeholder={
                                                                      text_entercontactname
                                                                    }
                                                                    value={
                                                                      inputField.contactname
                                                                    }
                                                                    name="contactname"
                                                                    onChange={(
                                                                      event
                                                                    ) =>
                                                                      handleChangeInput(
                                                                        inputField.partner_id,
                                                                        event
                                                                      )
                                                                    }
                                                                  />
                                                                  {asexclusive ===
                                                                  false ? (
                                                                    <>
                                                                      <button
                                                                        className="deleteBtn"
                                                                        onClick={() =>
                                                                          handleRemoveFields(
                                                                            inputField.partner_id
                                                                          )
                                                                        }
                                                                      >
                                                                        <RxCross2 className="crossIcon" />
                                                                      </button>
                                                                    </>
                                                                  ) : (
                                                                    <></>
                                                                  )}
                                                                </div>
                                                              </>
                                                            ) : (
                                                              <></>
                                                            )}
                                                          </div>
                                                        </>
                                                      );
                                                    }
                                                  )}
                                                  {showVerifiedDetails ===
                                                  false ? (
                                                    <>
                                                      <div className="my-2">
                                                        <button
                                                          className="main-btn"
                                                          onClick={
                                                            confirmOwnerHandle
                                                          }
                                                        >
                                                          {text_verify}
                                                        </button>
                                                      </div>
                                                    </>
                                                  ) : (
                                                    <></>
                                                  )}
                                                </>
                                              ) : (
                                                ""
                                              )}
                                            </div>
                                          </>
                                        ) : (
                                          <></>
                                        )}
                                      </>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                </div>
                              </>
                            ) : (
                              <></>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    {isNext === false ? (
                      <>
                        <div className="step-footer step-tab-pager mt-4">
                          <button
                            data-direction="prev"
                            className="btn btn-default btn-hover steps_btn me-2"
                            onClick={() =>
                              navigate(-1, {
                                state: { event_id: eveid },
                              })
                            }
                          >
                            {text_close}
                          </button>
                          <button
                            data-direction="next"
                            className="btn btn-default btn-hover steps_btn"
                            onClick={() => setIsNext(true)}
                            disabled={showNext === false ? true : false}
                          >
                            {text_next}{" "}
                          </button>
                        </div>
                      </>
                    ) : (
                      <></>
                    )}
                    {isNext === true ? (
                      <>
                        <div className="step-footer step-tab-pager mt-4">
                          <button
                            data-direction="prev"
                            className="btn btn-default btn-hover steps_btn me-2"
                            onClick={() => [
                              setIsNext(false),
                              setselectedSeatPrice(0),
                              setAsdigitalticket(false),
                              setselectedSeatIsVIP(false),
                              setselectedSeatIsDisable(false),
                              setSelectedBlocking(false),
                              setSelectedPartnerDetail(false),
                              setInputFields([
                                {
                                  partner_id: uuidv4(),
                                  partnername: "",
                                  contactname: "",
                                  email: "",
                                  mobile: "",
                                  verify: false,
                                },
                              ]),
                            ]}
                          >
                            {text_close}
                          </button>
                          <button
                            data-direction="next"
                            className="btn btn-default btn-hover steps_btn"
                            onClick={() => submitSeats()}
                          >
                            {text_save}{" "}
                          </button>
                        </div>
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
      <ToastContainer />
    </>
  );
}

export default SeatConfiguration;
